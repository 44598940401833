import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { chunk } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'src/client/redux';
import { useCategories } from 'src/client/requests/useCategories';
import { ExtendedFeaturedTag } from 'src/@types/ExtendedFeaturedTags';

type SeoCategoriesPopoverProps = {
  menuIsOpen: boolean;
  hideMenu: () => void;
  showMenu: () => void;
};

export const SeoCategoriesPopover = ({ menuIsOpen, showMenu, hideMenu }: SeoCategoriesPopoverProps) => {
  const config = useSelector((state) => state.app.config);
  const { data } = useCategories();

  if (!menuIsOpen || !data || !data.tags[0]) {
    return null;
  }

  const topCategories = data.tags[0].featuredTags.filter(({ isTopCategory }) => isTopCategory);
  const [leftColumn, rightColumn] = chunk(topCategories, Math.ceil(topCategories.length / 2));

  return (
    <Popover className="hidden-xs man-popover" onMouseEnter={showMenu} onMouseLeave={hideMenu}>
      <div className="man-arrow dark-man-arrow" />
      <PopoverTitle>Popular categories</PopoverTitle>
      <Columns>
        <Column data-testid="seo-categories-left-column">
          {leftColumn.map((tag) => (
            <TagLink key={tag.UUID} tag={tag} onClick={hideMenu} />
          ))}
        </Column>
        <Column data-testid="seo-categories-right-column">
          {rightColumn.map((tag) => (
            <TagLink key={tag.UUID} tag={tag} onClick={hideMenu} />
          ))}
        </Column>
      </Columns>
      {config.hasCategories && (
        <AllCategoryWrapper>
          <Link exact to={`/tags`} onClick={hideMenu}>
            <FontAwesomeIcon icon={faTags} />
            All categories
          </Link>
        </AllCategoryWrapper>
      )}
    </Popover>
  );
};

const TagLink = ({ tag: { name, url, displayName }, onClick }: { tag: ExtendedFeaturedTag; onClick: () => void }) => (
  <Link to={`/tags/${encodeURI(url || name)}`} onClick={onClick}>
    {displayName || name}
  </Link>
);

const Popover = styled.div`
  background: ${(props) => props.theme.primary1};
  width: 400px;
  border-color: ${(props) => props.theme.primary3};
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 0 0 15px;
`;

const PopoverTitle = styled.h2`
  font-size: 17px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 150px;
`;

const Link = styled(NavLink)`
  margin-bottom: 14px;

  &:hover {
    text-decoration: none;
  }

  // done this way to have high specificity
  &&:not(.btn):not(.active):hover {
    color: ${(props) => props.theme.primary4};
  }
`;

const AllCategoryWrapper = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0.5rem;
  }
`;
