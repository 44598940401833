import { useRouteMatch } from 'react-router';
import { FeatureFlags } from 'src/@types/SiteConfig';
import { useSelector } from '../redux';

export const useFeatureFlags = (): FeatureFlags => {
  const siteConfig = useSelector((state) => state.app.config);
  return siteConfig?.featureFlags || {};
};

/**
 * @returns True if the enableWideModelPage feature flag is true the current route is /model/*
 */
export const useWidePage = (): boolean => {
  const { enableWideModelPage = false } = useFeatureFlags();
  const match = useRouteMatch('/model');
  return match ? enableWideModelPage : false;
};
